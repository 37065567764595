<template>
  <div>
    <div class="h-text-center">
      <div class="h-m-t-10">
        <van-image :src="require('@/assets/images/pay_success.png')" style="height:120px;width:120px" />
      </div>
      <div class="h-font-lg h-font-bold-sm h-m-t-10">
        <span class="h-primary-color">恭喜您！</span>
        <span>提交成功！</span>
      </div>
      <div class="h-font-md h-text-secondary-color h-m-t-10">申请号：{{ claimNo }}</div>
    </div>
    <!--<div class="btn">
<van-button type="primary" block @click="toContractDetail">查看</van-button>
</div>-->
  </div>
</template>

<script>
export default {
  name: "SuccessClaim",
  inject: {
    pageContext: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      pageData: {
        interval: null
      },
      productCode: null,
      claimNo: "",
      id: null
    };
  },
  created() {
    const { productCode, id, claimNo } = this.$route.query;
    this.id = id;
    this.claimNo = claimNo;
    this.productCode = productCode;
    this.initInterval();
    this.pageContext.pageData.storageFlag = false;
  },
  methods: {
    initInterval() {
      this.pageData.interval = setInterval(this.calcInterval, 1000 * 3);
    },
    calcInterval() {
      this.clearInterval();
      this.toClaimList();
    },
    clearInterval() {
      this.pageData.interval && clearInterval(this.pageData.interval);
    },
    toClaimList() {
      this.$router.replace({ path: "/product/claim/list" });
    }
  },
  beforeDestroy() {
    this.clearInterval();
  }
};
</script>
<style lang="scss" scoped>
.btn {
  margin-top: 30px;
  padding: 0 10px;
}
</style>
